<template>
    <!-- view profile details -->
    <div id="user-profile">
        <!-- profile header -->
        <div class="row">
            <div class="col-12">
                <div class="card profile-header mb-2">
                    <!-- profile cover photo -->
                    <img class="card-img-top" src="../../assets/images/profile/user-uploads/timeline.jpg" alt="User Profile Image" />
                    <!--/ profile cover photo -->

                    <div class="position-relative">
                        <!-- profile picture -->
                        <div class="profile-img-container d-flex align-items-center">
                            <div class="profile-img">
                                <img src="../../assets/images/portrait/small/avatar-s-2.jpg" class="rounded img-fluid" alt="Card image" />
                            </div>
                            <!-- profile title -->
                            <div class="profile-title ms-3">
                                <h2 class="text-white">Kitty Allanson</h2>
                                <p class="text-white">UI/UX Designer</p>
                            </div>
                        </div>
                    </div>

                    <!-- tabs pill -->
                    <div class="profile-header-nav">
                        <!-- navbar -->
                        <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
                            <button class="btn btn-icon navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i data-feather="align-justify" class="font-medium-5"></i>
                            </button>

                            <!-- collapse  -->
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <div class="profile-tabs d-flex justify-content-between flex-wrap mt-1 mt-md-0">
                                    <ul class="nav nav-pills mb-0">
                                        <li class="nav-item">
                                            <a class="nav-link fw-bold active" href="#">
                                                <span class="d-none d-md-block">Feed</span>
                                                <i data-feather="rss" class="d-block d-md-none"></i>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link fw-bold" href="#">
                                                <span class="d-none d-md-block">About</span>
                                                <i data-feather="info" class="d-block d-md-none"></i>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link fw-bold" href="#">
                                                <span class="d-none d-md-block">Photos</span>
                                                <i data-feather="image" class="d-block d-md-none"></i>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link fw-bold" href="#">
                                                <span class="d-none d-md-block">Friends</span>
                                                <i data-feather="users" class="d-block d-md-none"></i>
                                            </a>
                                        </li>
                                    </ul>
                                    <!-- edit button -->
                                    <button class="btn btn-primary">
                                        <i data-feather="edit" class="d-block d-md-none"></i>
                                        <span class="fw-bold d-none d-md-block">Edit</span>
                                    </button>
                                </div>
                            </div>
                            <!--/ collapse  -->
                        </nav>
                        <!--/ navbar -->
                    </div>
                </div>
            </div>
        </div>
        <!--/ profile header -->

        <!-- profile info section -->
        <section id="profile-info">
            <div class="row">
                <!-- left profile info section -->
                <div class="col-lg-3 col-12 order-2 order-lg-1">
                    <!-- about -->
                    <div class="card">
                        <div class="card-body">
                            <h5 class="mb-75">About</h5>
                            <p class="card-text">
                                Tart I love sugar plum I love oat cake. Sweet ⭐️ roll caramels I love jujubes. Topping cake wafer.
                            </p>
                            <div class="mt-2">
                                <h5 class="mb-75">Joined:</h5>
                                <p class="card-text">November 15, 2015</p>
                            </div>
                            <div class="mt-2">
                                <h5 class="mb-75">Lives:</h5>
                                <p class="card-text">New York, USA</p>
                            </div>
                            <div class="mt-2">
                                <h5 class="mb-75">Email:</h5>
                                <p class="card-text">bucketful@fiendhead.org</p>
                            </div>
                            <div class="mt-2">
                                <h5 class="mb-50">Website:</h5>
                                <p class="card-text mb-0">www.pixinvent.com</p>
                            </div>
                        </div>
                    </div>
                    <!--/ about -->

                    <!-- suggestion pages -->
                    <div class="card">
                        <div class="card-body profile-suggestion">
                            <h5 class="mb-2">Suggested Pages</h5>
                            <!-- user suggestions -->
                            <div class="d-flex justify-content-start align-items-center mb-1">
                                <div class="avatar me-1">
                                    <img src="../../assets/images/avatars/12-small.png" alt="avatar img" height="40" width="40" />
                                </div>
                                <div class="profile-user-info">
                                    <h6 class="mb-0">Peter Reed</h6>
                                    <small class="text-muted">Company</small>
                                </div>
                                <div class="profile-star ms-auto"><i data-feather="star" class="font-medium-3"></i></div>
                            </div>
                            <!-- user suggestions -->
                            <div class="d-flex justify-content-start align-items-center mb-1">
                                <div class="avatar me-1">
                                    <img src="../../assets/images/avatars/1-small.png" alt="avatar" height="40" width="40" />
                                </div>
                                <div class="profile-user-info">
                                    <h6 class="mb-0">Harriett Adkins</h6>
                                    <small class="text-muted">Company</small>
                                </div>
                                <div class="profile-star ms-auto"><i data-feather="star" class="font-medium-3"></i></div>
                            </div>
                            <!-- user suggestions -->
                            <div class="d-flex justify-content-start align-items-center mb-1">
                                <div class="avatar me-1">
                                    <img src="../../assets/images/avatars/10-small.png" alt="avatar" height="40" width="40" />
                                </div>
                                <div class="profile-user-info">
                                    <h6 class="mb-0">Juan Weaver</h6>
                                    <small class="text-muted">Company</small>
                                </div>
                                <div class="profile-star ms-auto"><i data-feather="star" class="font-medium-3"></i></div>
                            </div>
                            <!-- user suggestions -->
                            <div class="d-flex justify-content-start align-items-center mb-1">
                                <div class="avatar me-1">
                                    <img src="../../assets/images/avatars/3-small.png" alt="avatar img" height="40" width="40" />
                                </div>
                                <div class="profile-user-info">
                                    <h6 class="mb-0">Claudia Chandler</h6>
                                    <small class="text-muted">Company</small>
                                </div>
                                <div class="profile-star ms-auto"><i data-feather="star" class="font-medium-3"></i></div>
                            </div>
                            <!-- user suggestions -->
                            <div class="d-flex justify-content-start align-items-center mb-1">
                                <div class="avatar me-1">
                                    <img src="../../assets/images/avatars/5-small.png" alt="avatar img" height="40" width="40" />
                                </div>
                                <div class="profile-user-info">
                                    <h6 class="mb-0">Earl Briggs</h6>
                                    <small class="text-muted">Company</small>
                                </div>
                                <div class="profile-star ms-auto">
                                    <i data-feather="star" class="profile-favorite font-medium-3"></i>
                                </div>
                            </div>
                            <!-- user suggestions -->
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="avatar me-1">
                                    <img src="../../assets/images/avatars/6-small.png" alt="avatar img" height="40" width="40" />
                                </div>
                                <div class="profile-user-info">
                                    <h6 class="mb-0">Jonathan Lyons</h6>
                                    <small class="text-muted">Beauty Store</small>
                                </div>
                                <div class="profile-star ms-auto"><i data-feather="star" class="font-medium-3"></i></div>
                            </div>
                        </div>
                    </div>
                    <!--/ suggestion pages -->
                </div>
                <!--/ left profile info section -->

                <!-- center profile info section -->
                <div class="col-lg-6 col-12 order-1 order-lg-2">
                    <!-- post 1 -->
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-start align-items-center mb-1">
                                <!-- avatar -->
                                <div class="avatar me-1">
                                    <img src="../../assets/images/portrait/small/avatar-s-18.jpg" alt="avatar img" height="50" width="50" />
                                </div>
                                <!--/ avatar -->
                                <div class="profile-user-info">
                                    <h6 class="mb-0">Leeanna Alvord</h6>
                                    <small class="text-muted">12 Dec 2018 at 1:16 AM</small>
                                </div>
                            </div>
                            <p class="card-text">
                                Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This
                                can make the difference when presenting to clients who are looking for the perfect fit.
                            </p>
                            <!-- post img -->
                            <img class="img-fluid rounded mb-75" src="../../assets/images/profile/post-media/2.jpg" alt="avatar img" />
                            <!--/ post img -->

                            <!-- like share -->
                            <div class="row d-flex justify-content-start align-items-center flex-wrap pb-50">
                                <div class="col-sm-6 d-flex justify-content-between justify-content-sm-start mb-2">
                                    <a href="#" class="d-flex align-items-center text-muted text-nowrap">
                                        <i data-feather="heart" class="profile-likes font-medium-3 me-50"></i>
                                        <span>1.25k</span>
                                    </a>

                                    <!-- avatar group with tooltip -->
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-group ms-1">
                                            <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Trina Lynes" class="avatar pull-up">
                                                <img src="../../assets/images/portrait/small/avatar-s-1.jpg" alt="Avatar" height="26" width="26" />
                                            </div>
                                            <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Lilian Nenez" class="avatar pull-up">
                                                <img src="../../assets/images/portrait/small/avatar-s-2.jpg" alt="Avatar" height="26" width="26" />
                                            </div>
                                            <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Alberto Glotzbach" class="avatar pull-up">
                                                <img src="../../assets/images/portrait/small/avatar-s-3.jpg" alt="Avatar" height="26" width="26" />
                                            </div>
                                            <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="George Nordic" class="avatar pull-up">
                                                <img src="../../assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                                            </div>
                                            <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Vinnie Mostowy" class="avatar pull-up">
                                                <img src="../../assets/images/portrait/small/avatar-s-4.jpg" alt="Avatar" height="26" width="26" />
                                            </div>
                                        </div>
                                        <a href="#" class="text-muted text-nowrap ms-50">+140 more</a>
                                    </div>
                                    <!-- avatar group with tooltip -->
                                </div>

                                <!-- share and like count and icons -->
                                <div class="col-sm-6 d-flex justify-content-between justify-content-sm-end align-items-center mb-2">
                                    <a href="#" class="text-nowrap">
                                        <i data-feather="message-square" class="text-body font-medium-3 me-50"></i>
                                        <span class="text-muted me-1">1.25k</span>
                                    </a>

                                    <a href="#" class="text-nowrap">
                                        <i data-feather="share-2" class="text-body font-medium-3 mx-50"></i>
                                        <span class="text-muted">1.25k</span>
                                    </a>
                                </div>
                                <!-- share and like count and icons -->
                            </div>
                            <!-- like share -->

                            <!-- comments -->
                            <div class="d-flex align-items-start mb-1">
                                <div class="avatar mt-25 me-75">
                                    <img src="../../assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="34" width="34" />
                                </div>
                                <div class="profile-user-info w-100">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6 class="mb-0">Kitty Allanson</h6>
                                        <a href="#">
                                            <i data-feather="heart" class="text-body font-medium-3"></i>
                                            <span class="align-middle text-muted">34</span>
                                        </a>
                                    </div>
                                    <small>Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.</small>
                                </div>
                            </div>
                            <div class="d-flex align-items-start mb-1">
                                <div class="avatar mt-25 me-75">
                                    <img src="../../assets/images/portrait/small/avatar-s-8.jpg" alt="Avatar" height="34" width="34" />
                                </div>
                                <div class="profile-user-info w-100">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6 class="mb-0">Jackey Potter</h6>
                                        <a href="#">
                                            <i data-feather="heart" class="profile-likes font-medium-3"></i>
                                            <span class="align-middle text-muted">61</span>
                                        </a>
                                    </div>
                                    <small>
                                        Unlimited color🖌 options allows you to set your application color as per your branding 🤪.
                                    </small>
                                </div>
                            </div>
                            <!--/ comments -->


                        </div>
                    </div>
                    <!--/ post 1 -->
                </div>
                <!--/ center profile info section -->

                <!-- right profile info section -->
                <div class="col-lg-3 col-12 order-3">

                        <!-- suggestion -->
                        <div class="card">
                            <div class="card-body">
                                <h5>Suggestions</h5>
                                <div class="d-flex justify-content-start align-items-center mt-2">
                                    <div class="avatar me-75">
                                        <img src="../../assets/images/portrait/small/avatar-s-9.jpg" alt="avatar" height="40" width="40" />
                                    </div>
                                    <div class="profile-user-info">
                                        <h6 class="mb-0">Peter Reed</h6>
                                        <small class="text-muted">6 Mutual Friends</small>
                                    </div>
                                    <button type="button" class="btn btn-primary btn-icon btn-sm ms-auto">
                                        <i data-feather="user-plus"></i>
                                    </button>
                                </div>
                                <div class="d-flex justify-content-start align-items-center mt-1">
                                    <div class="avatar me-75">
                                        <img src="../../assets/images/portrait/small/avatar-s-6.jpg" alt="avtar img holder" height="40" width="40" />
                                    </div>
                                    <div class="profile-user-info">
                                        <h6 class="mb-0">Harriett Adkins</h6>
                                        <small class="text-muted">3 Mutual Friends</small>
                                    </div>
                                    <button type="button" class="btn btn-primary btn-sm btn-icon ms-auto">
                                        <i data-feather="user-plus"></i>
                                    </button>
                                </div>
                                <div class="d-flex justify-content-start align-items-center mt-1">
                                    <div class="avatar me-75">
                                        <img src="../../assets/images/portrait/small/avatar-s-7.jpg" alt="avatar" height="40" width="40" />
                                    </div>
                                    <div class="profile-user-info">
                                        <h6 class="mb-0">Juan Weaver</h6>
                                        <small class="text-muted">1 Mutual Friends</small>
                                    </div>
                                    <button type="button" class="btn btn-sm btn-primary btn-icon ms-auto">
                                        <i data-feather="user-plus"></i>
                                    </button>
                                </div>
                                <div class="d-flex justify-content-start align-items-center mt-1">
                                    <div class="avatar me-75">
                                        <img src="../../assets/images/portrait/small/avatar-s-8.jpg" alt="avatar img" height="40" width="40" />
                                    </div>
                                    <div class="profile-user-info">
                                        <h6 class="mb-0">Claudia Chandler</h6>
                                        <small class="text-muted">16 Mutual Friends</small>
                                    </div>
                                    <button type="button" class="btn btn-sm btn-primary btn-icon ms-auto">
                                        <i data-feather="user-plus"></i>
                                    </button>
                                </div>
                                <div class="d-flex justify-content-start align-items-center mt-1">
                                    <div class="avatar me-75">
                                        <img src="../../assets/images/portrait/small/avatar-s-1.jpg" alt="avatar img" height="40" width="40" />
                                    </div>
                                    <div class="profile-user-info">
                                        <h6 class="mb-0">Earl Briggs</h6>
                                        <small class="text-muted">4 Mutual Friends</small>
                                    </div>
                                    <button type="button" class="btn btn-sm btn-primary btn-icon ms-auto">
                                        <i data-feather="user-plus"></i>
                                    </button>
                                </div>
                                <div class="d-flex justify-content-start align-items-center mt-1">
                                    <div class="avatar me-75">
                                        <img src="../../assets/images/portrait/small/avatar-s-10.jpg" alt="avatar img" height="40" width="40" />
                                    </div>
                                    <div class="profile-user-info">
                                        <h6 class="mb-0">Jonathan Lyons</h6>
                                        <small class="text-muted">25 Mutual Friends</small>
                                    </div>
                                    <button type="button" class="btn btn-sm btn-primary btn-icon ms-auto">
                                        <i data-feather="user-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!--/ suggestion -->

                              <!-- polls card -->
                               <div class="card">
                                    <div class="card-body">
                                        <h5 class="mb-1">Polls</h5>
                                        <p class="card-text mb-0">Who is the best actor in Marvel Cinematic Universe?</p>

                                           <!-- polls -->
                                           <div class="profile-polls-info mt-2">
                                                <!-- custom radio -->
                                                <div class="d-flex justify-content-between">
                                                    <div class="form-check">
                                                        <input type="radio" id="bestActorPoll1" name="bestActorPoll" class="form-check-input" />
                                                        <label class="form-check-label" for="bestActorPoll1">RDJ</label>
                                                    </div>
                                                    <div class="text-end">82%</div>
                                                </div>
                                                <!--/ custom radio -->

                                                <!-- progressbar -->
                                                <div class="progress progress-bar-primary my-50">
                                                    <div class="progress-bar" role="progressbar"
                                                         aria-valuenow="58" aria-valuemin="58" aria-valuemax="100">
                                                     </div>
                                                </div>
                                                <!--/ progressbar -->

                                                <!-- avatar group with tooltip -->
                                                <div class=" avatar-group my-1">
                                                            <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Tonia Seabold" class="avatar pull-up">
                                                                <img src="../../assets/images/portrait/small/avatar-s-12.jpg" alt="Avatar" height="26" width="26" />
                                                            </div>
                                                            <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Carissa Dolle" class="avatar pull-up">
                                                                <img src="../../assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                                                            </div>
                                                            <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Kelle Herrick" class="avatar pull-up">
                                                                <img src="../../assets/images/portrait/small/avatar-s-9.jpg" alt="Avatar" height="26" width="26" />
                                                            </div>
                                                            <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Len Bregantini" class="avatar pull-up">
                                                                <img src="../../assets/images/portrait/small/avatar-s-10.jpg" alt="Avatar" height="26" width="26" />
                                                            </div>
                                                            <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="John Doe" class="avatar pull-up">
                                                                <img src="../../assets/images/portrait/small/avatar-s-11.jpg" alt="Avatar" height="26" width="26" />
                                                            </div>
                                                </div>
                                                <!--/ avatar group with tooltip -->
                                            </div>

                                            <div class="profile-polls-info mt-2">
                                                <div class="d-flex justify-content-between">
                                                    <!-- custom radio -->
                                                    <div class="form-check">
                                                        <input type="radio" id="bestActorPoll2" name="bestActorPoll" class="form-check-input" />
                                                        <label class="form-check-label" for="bestActorPoll2">Chris Hemswort</label>
                                                    </div>
                                                    <!--/ custom radio -->
                                                    <div class="text-end">67%</div>
                                                </div>
                                                <!-- progressbar -->
                                                <div class="progress progress-bar-primary my-50">
                                                    <div class="progress-bar" role="progressbar" aria-valuenow="16" aria-valuemin="16" aria-valuemax="100">
                                                    </div>
                                                </div>
                                                <!--/ progressbar -->

                                                <!-- avatar group with tooltips -->
                                                 <div class=" avatar-group mt-1">
                                                        <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Liliana Pecor" class="avatar pull-up">
                                                            <img src="../../assets/images/portrait/small/avatar-s-9.jpg" alt="Avatar" height="26" width="26" />
                                                        </div>
                                                        <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Kasandra NaleVanko" class="avatar pull-up">
                                                            <img src="../../assets/images/portrait/small/avatar-s-1.jpg" alt="Avatar" height="26" width="26" />
                                                        </div>
                                                        <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Jonathan Lyons" class="avatar pull-up">
                                                            <img src="../../assets/images/portrait/small/avatar-s-8.jpg" alt="Avatar" height="26" width="26" />
                                                        </div>
                                                 </div>
                                                <!--/ avatar group with tooltips-->
                                            </div>
                                            <!--/ polls -->

                                            </div>
                                </div>
                                <!--/ polls card -->
                </div>
                <!--/ right profile info section -->
                    </div>

        </section>
        <!--/ profile info section -->
    </div>
    <!-- view profile details -->
</template>



<style scoped>
#user-profile {
  /*-------------profile header section---------*/
  /*-------- profile info section --------*/ }

#user-profile .profile-header {
  overflow: hidden; }

#user-profile .profile-header .profile-img-container {
  position: absolute;
  bottom: -2rem;
  left: 2.14rem;
  z-index: 2; }

#user-profile .profile-header .profile-img-container .profile-img {
  height: 8.92rem;
  width: 8.92rem;
  border: 0.357rem solid #fff;
  background-color: #fff;
  border-radius: 0.428rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1); }

#user-profile .profile-header .profile-header-nav .navbar {
  padding: 0.8rem 1rem; }

#user-profile .profile-header .profile-header-nav .navbar .navbar-toggler {
  line-height: 0; }

#user-profile .profile-header .profile-header-nav .navbar .profile-tabs .nav-item i,
#user-profile .profile-header .profile-header-nav .navbar .profile-tabs .nav-item svg {
  margin-right: 0; }

#user-profile #profile-info .profile-star {
  color: #babfc7; }

#user-profile #profile-info .profile-star i.profile-favorite,
#user-profile #profile-info .profile-star svg.profile-favorite {
  fill: #ff9f43;
  stroke: #ff9f43; }

#user-profile #profile-info .profile-likes {
  fill: #ea5455;
  stroke: #ea5455; }

#user-profile #profile-info .profile-polls-info .progress {
  height: 0.42rem; }

#user-profile .profile-latest-img {
  transition: all 0.2s ease-in-out; }

#user-profile .profile-latest-img:hover {
  transform: translateY(-4px) scale(1.2);
  z-index: 10; }

#user-profile .profile-latest-img img {
  margin-top: 1.28rem; }

#user-profile .block-element .spinner-border {
  border-width: 0.14rem; }

@media (max-width: 991.98px) {
  #user-profile .profile-latest-img img {
    width: 100%; } }

@media (min-width: 768px) {
  .profile-header-nav .profile-tabs {
    width: 100%;
    margin-left: 13.2rem; } }

@media (max-width: 575.98px) {
  #user-profile .profile-header .profile-img-container .profile-img {
    height: 100px;
    width: 100px; }
  #user-profile .profile-header .profile-img-container .profile-title h2 {
    font-size: 1.5rem; } }

</style>
